// InjectedHits.js
import React from 'react';
import { connectHits } from 'react-instantsearch-dom';
import { ConnectInjectedHits } from './ConnectInjectedHits';
import Results from '../SearchExperiences/Results';

export const InjectedHits = connectHits(
  ConnectInjectedHits(({ injectedHits, ...props }) => <Results
    {...props}
    hits={injectedHits}
  />
  )
);


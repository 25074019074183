/* eslint-disable max-lines */
import { WEB_SALES_BANNER_OPTIONS } from '../../../lib/shared/enums';
import getWebSalesBannerStyles from '../../../lib/getWebSalesBannerStyles';

const styles = ({ spacing, palette, typography, breakpoints, transitions, shadows }) => ({
  cardV2: {
    height: 400,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    backgroundColor: 'transparent',
    borderRadius: 16,
    position: 'relative',
    transition: 'margin 250ms',
    marginTop: 0,
    overflow: 'visible',
    '&.variant2': {
      height: 'auto'
    },
    '&.variant3': {
      height: '100%'
    },
    '&:hover': {
      '& $mediaV2 img': {
        [breakpoints.up('md')]: {
          transform: 'scale(1.06)'
        }
      }
    }
  },
  cardMobile: {
    marginTop: '0px'
  },
  mediaV2: {
    height: '60%',
    width: '100%',
    overflow: 'hidden',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
      transition: transitions.create('transform', {
        duration: transitions.duration.complex,
        easing: transitions.easing.easeInOut
      })
    }
  },
  focusHighlight: {},
  content: {
    background: palette.common.white,
    paddingTop: spacing(1),
    '&.variant3': {
      height: '55%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: spacing(1)
    }
  },
  contentDark: {
    background: '#161e2b',
    paddingTop: spacing(1)
  },
  discountV2Dark: {
    paddingLeft: spacing(1 / 2),
    color: '#1AEAEB',
    fontWeight: 600,
    fontSize: spacing(2.25)
  },
  highlightedIcon: {
    stroke: '#FAAF3D',
    color: '#FAAF3D'
  },
  bannerBox: {
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    padding: `${spacing(1 / 4)} ${spacing(1 / 2)}`,
    borderRadius: spacing(1 / 2),
    '& span': {
      fontWeight: 500,
      fontSize: spacing(1.25),
      lineHeight: 1.5
    },
    '& svg': {
      fontSize: spacing(2) * 2
    }
  },
  bannerPriceBox: {
    height: spacing(3.25),
    marginTop: spacing(.5),
    display: 'flex',
    alignItems: 'flex-end',
    '&.variant2': {
      marginTop: 0
    }
  },
  embedSocialReview: {
    display: 'flex',
    position: 'relative'
  },
  eventNameV2: {
    height: spacing(5),
    fontWeight: 600,
    lineHeight: 1.2,
    fontSize: spacing(2),
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&.variant2': {
      height: 'auto',
      WebkitLineClamp: 3,
      overflow: 'hidden',
      fontSize: spacing(2)
    },
    '&.variant3': {
      height: 'auto',
      WebkitLineClamp: 3,
      overflow: 'hidden',
      fontSize: '12px'
    }
  },
  adTileTitle: {
    color: `${palette.primary.main} !important`,
    height: spacing(3.5),
    fontWeight: 600,
    lineHeight: 1.2,
    paddingTop: spacing(.5),
    fontSize: spacing(2.5),
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  adTileSubtext: {
    height: spacing(7.5),
    marginBottom: spacing(1.75),
    fontWeight: 400,
    lineHeight: 1.4,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  mobileAdTile: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '150px',
    '@media (min-width: 520px)': {
      height: '160px'
    },
    '@media (min-width: 560px)': {
      height: '170px'
    }
  },
  mobileAdImage: {
    position: 'absolute',
    width: '100%',
    height: 'auto',
    minWidth: '100%',
    minHeight: '100%',
    zIndex: -1
  },
  adTileMobileText: {
    color: 'white !important',
    height: spacing(7),
    fontWeight: 600,
    lineHeight: 1.4,
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
    marginRight: spacing(2),
    marginLeft: spacing(2),
    marginTop: spacing(4),
    fontSize: spacing(2.5),
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center'
  },
  adTileMobileLinkButton: {
    backgroundColor: 'white',
    color: palette.primary.main,
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
    marginBottom: spacing(4),
    height: spacing(3.5),
    textTransform: 'none',
    '&:hover': {
      backgroundColor: palette.primary.main,
      color: 'white',
      border: 'none'
    }
  },
  adTileDesktopLinkButton: {
    color: 'white',
    backgroundColor: '#156FF8',
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
    height: spacing(3.5),
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#0956cd',
      border: 'none'
    }
  },
  timingBox: {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      fontSize: spacing(1.25),
      paddingLeft: spacing(1 / 2),
      lineHeight: 1
    },
    '& svg': {
      fontSize: spacing(2)
    }
  },
  priceBox: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    paddingLeft: spacing(1),
    '&.variant2': {
      fontSize: '14px !important'
    },
    '&.fromOnTop': {
      alignItems: 'flex-end',
      flexDirection: 'column',
      width: 'max-content'
    }
  },
  priceV2: {
    paddingLeft: spacing(1),
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: spacing(2.25),
    '&.fromOnTop': {
      paddingLeft: 0,
      lineHeight: .7
    }
  },
  priceV2Dark: {
    paddingLeft: spacing(1),
    color: palette.common.white,
    fontWeight: 600,
    fontSize: spacing(2.25)
  },
  discountedV2: {
    paddingLeft: spacing(1 / 2),
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: spacing(2),
    textDecoration: 'line-through',
    textDecorationThickness: `${spacing(1 / 4)}`,
    '&.variant3': {
      lineHeight: .5
    }
  },
  discountedV2Dark: {
    paddingLeft: spacing(1 / 2),
    color: palette.common.white,
    fontWeight: 600,
    fontSize: spacing(2),
    textDecoration: 'line-through',
    textDecorationThickness: `${spacing(1 / 4)}`,
    '&.variant3': {
      lineHeight: .5
    }
  },
  iconBoxV3: {
    marginTop: spacing(1),
    height: '40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end'
  },
  fromV2: {
    textTransform: 'lowercase',
    lineHeight: 1.9
  },
  discountedFromV2: {
    textTransform: 'lowercase',
    color: (palette.calendar_discount_v3 || palette.calendar_discount).main
  },
  discountedFromV2Dark: {
    textTransform: 'lowercase',
    color: palette.common.white
  },
  discountedPrefixV2: {
    paddingLeft: spacing(.5),
    color: (palette.calendar_discount_v3 || palette.calendar_discount).main,
    fontWeight: 600,
    fontSize: spacing(2.25),
    '&.variant3': {
      lineHeight: .5,
      color: 'black'
    }
  },
  discountedPrefixV2Dark: {
    paddingLeft: spacing(.5),
    color: palette.common.black,
    fontWeight: 600,
    fontSize: spacing(2.25),
    '.variant3': {
      lineHeight: .5,
      color: 'inherit'
    }
  },
  tagColorDark: {
    color: '#FFE6FF'
  },
  bannerIcon: {
    width: spacing(2),
    marginRight: spacing(1 / 2)
  },

  locationV2: {
    height: spacing(2.625),
    display: 'flex',
    alignItems: 'center',
    '@media (min-width:960px) and (max-width:1080px)': {
      fontSize: spacing(1.5)
    },
    '&.variant2': {
      fontSize: spacing(1.5)
    }
  },
  actionAreaV2: {
    height: '100%',
    borderRadius: spacing(2),
    overflow: 'hidden',
    position: 'relative',
    zIndex: 0,
    '&:hover $focusHighlight': {
      opacity: 0
    }
  },
  bannerContainer: {
    minWidth: '120px'
  },
  subCategoryContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: spacing(1)
  },
  subCategory: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      color: palette.text.secondary,
      fontSize: spacing(.5)
    },
    '& span': {
      paddingLeft: spacing(1),
      fontSize: typography.caption.fontSize,
      '@media (min-width:960px) and (max-width:1080px)': {
        fontSize: spacing(1.25),
        lineHeight: 1.5
      }
    }
  },
  promoText: {
    position: 'absolute',
    right: 0,
    fontWeight: 'bold',
    padding: spacing(.5),
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
    boxShadow: shadows[3],
    borderRadius: '0px 16px 0px 16px',
    zIndex: 199,
    '&.variant2': {
      left: 0,
      paddingLeft: spacing(1),
      boxShadow: 'none'
    },
    '&.alignLeft': {
      borderRadius: '16px 0px 16px 0px',
      left: 0,
      right: 'auto',
      fontSize: '12px'
    }
  },
  tagContainer: {
    display: 'flex'
  },
  notFirstBanner: {
    marginLeft: spacing(.5)
  },
  bannersContainer: {
    display: 'flex'
  },
  variant2EventName: {
    fontWeight: 600,
    lineHeight: 1.2,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
    height: 'auto',
    WebkitLineClamp: 3,
    overflow: 'hidden',
    fontSize: '14px'
  },
  locationVariant2: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '10px',
    marginRight: spacing(.5),
    '&.variant3': {
      minWidth: 'fit-content'
    }
  },
  webCategoriesVariant2: {
    fontSize: '10px',
    marginLeft: spacing(.5),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    WebkitLineClamp: 1
  },
  variant2Timing: {
    display: 'flex',
    marginTop: spacing(.2),
    minWidth: 0,
    alignItems: 'center',
    fontSize: '10px',
    '& svg': {
      fontSize: spacing(2),
      marginLeft: spacing(.5)
    }
  },
  variant2MainSection: {
    minHeight: '50px'
  },
  mediaVariant2: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    '&.variant3': {
      height: '45%'
    },
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
      transition: transitions.create('transform', {
        duration: transitions.duration.complex,
        easing: transitions.easing.easeInOut
      })
    }
  },
  variant2Content: {
    background: palette.common.white,
    paddingTop: spacing(1),
    padding: spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },
  variant2LocationCategory: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: spacing(1),
    '& svg': {
      color: palette.text.secondary,
      fontSize: spacing(.5)
    },
    '&.variant3': {
      marginBottom: 0,
      flexWrap: 'nowrap'
    }
  },
  variant2Category: {
    display: 'flex',
    alignItems: 'center',
    '&.variant3': {
      whiteSpace: ' nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      minWidth: '0',
      flex: '1'
    }
  },
  fromVariant3: {
    color: palette.common.black,
    fontSize: '10px',
    fontWeight: '400'
  },
  priceDurationVariant3: {
    flexWrap: 'nowrap'
  },
  taxIncludedBox: {
    lineHeight: 0,
    textAlign: 'right',
    fontStyle: 'italic',
    width: '100%'
  },
  taxIncludedText: {
    fontSize: '10px',
    lineHeight: 1,
    '&.text-large': {
      fontSize: '12px'
    }
  },
  priceAndFeesTextBox: {
    textAlign: 'right'
  },
  featuredTile: {
    boxShadow: '0px 0px 8px #FFC055'
  },
  ...getWebSalesBannerStyles({ data: WEB_SALES_BANNER_OPTIONS })({ palette })
});

export default styles;

import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { Text } from 'react-localize';

const styles = theme => ({
  root: {},
  showExperiencesText: {
    color: theme.palette.primary.main
  }
});

const AlgoliaInfiniteHits = ({ hasMore, refineNext, hits,
  renderResults, showNoResult, numberOfHits = 0, classes, ...props }) => {
  const handleLoadMore = () => {
    if (hasMore) {
      refineNext();
    }
  };

  return (
    <div>
      {renderResults({ hits, ...props, hasMore, isInfiniteHits: true })}
      <br/>
      <Grid container spacing={1} >
        {(hasMore && !showNoResult) && (
          <Grid item xs={12} justifyContent={'center'} alignItems={'center'} textAlign={'center'}>
            <Button onClick={handleLoadMore} color="primary" className={'load-more-btn btn btn-primary'}>
              <Text message={'loadMore'}/>
            </Button>
          </Grid>
        )}
        {(!showNoResult && !!(hits || []).length && <Grid item xs={12} justifyContent={'center'} alignItems={'center'}>
          <Typography variant="body2" textAlign={'center'}>
            <Text
              message={'showingExperiences'}
              values={[(hits || []).length, numberOfHits]}
              className={classes.showExperiencesText}/>
          </Typography>
        </Grid>)}
      </Grid>
    </div>
  );
};

export default connectInfiniteHits(withStyles(styles)(AlgoliaInfiniteHits));

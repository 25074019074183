/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import ResultItem from './ResultItem/ResultItem';
import Typography from '@mui/material/Typography';
import { Text } from 'react-localize';
import isEqual from 'lodash/isEqual';
import { loadEmbedSocialReview } from '../../lib/embedSocial';
import classNames from 'classnames';
import { Card, CardContent, CardMedia } from '@mui/material';
import { connectStateResults } from 'react-instantsearch-dom';
import search from '../../../util/getProductsOnHorizon';
import searchFlexibleDates from '../../../util/getFlexibleDatesData';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import cookie from 'cookiejs';
import { INCLUDED_PORTS_FOR_FEATURED_TILES_CAMPAIGN, VWO_CAMPAIGNS } from '../../lib/shared/enums';
import { isVwoTestActive } from '../../../util/isVwoTestActive';

const styles = theme => ({
  card: {
    height: 300,
    width: '100%',
    backgroundColor: '#ffffff',
    borderRadius: 16,
    position: 'relative',
    transition: 'margin 250ms',
    marginTop: theme.spacing(4),
    overflow: 'visible'
  },
  cardMobile: {
    marginTop: '0px'
  },
  media: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    height: '66%',
    width: '100%',
    backgroundPosition: 'initial',
    background: 'radial-gradient(circle at 18.7% 37.8%, rgb(245, 245, 245) 0%, rgb(233, 236, 240) 90%);'
  },
  content: {
    background: theme.palette.common.white,
    paddingTop: theme.spacing(1)
  },
  eventNameSkeleton: {
    height: theme.spacing(2.5),
    width: '75%',
    backgroundColor: 'rgb(245, 245, 245)'
  },
  cityNameSkeleton: {
    height: theme.spacing(1.5),
    width: '25%',
    marginTop: theme.spacing(2.5),
    backgroundColor: 'rgb(245, 245, 245)'
  },
  productsOnHorizonTitle: {
    paddingBottom: '35px'
  },
  noResultsProductsOnHorizonTitles: {
    paddingBottom: '35px'
  },
  noResultsProductsOnHorizonContainer: {
    backgroundColor: '#234d580d',
    borderRadius: '25px',
    padding: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2)
    }
  },
  onTheHorizonContainer: {
    marginTop: theme.spacing(3),
    backgroundColor: '#234d580d',
    borderRadius: '25px',
    padding: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2)
    }
  },
  horizonHeader: {
    fontSize: '28px !important',
    color: '#000000cc !important',
    marginTop: '0px !important'
  },
  felxibleDateMessage: {
    paddingBottom: '30px'
  },
  highlightedTile: {
    boxShadow: '0px 0px 8px #FFC055'
  }
});

const COOKIE_NAME = 'cex_campaign';
let isDisplayPriceWithFeesCampaignActive = false;
try {
  isDisplayPriceWithFeesCampaignActive = cookie.get(COOKIE_NAME) === VWO_CAMPAIGNS.DISPLAY_WITH_ALL_FEES;
} catch (err) {
  // eslint-disable-next-line no-console
  console.log('cookie error', err);
}

const Results = ({
  hits,
  siteId,
  abTestVariant,
  tealiumTrack,
  recentBookingsThreshold,
  sellOutThreshold,
  tourCurrencies,
  showPriceWithFees,
  isMobileView,
  adsTile,
  searchIndex,
  sendAlgoliaEvent,
  setAlgoliaSearchInfo,
  searchExperienceV3IsEnabled,
  showEmbedSocialReview,
  allowedDisplayLabels,
  shouldHideAdlabels,
  shouldAddCtaButton,
  shouldRedirectAd,
  showToursWithAvailabilityOnly,
  fixedHeight,
  setFixedHeight,
  toShowCentsPropertyIds,
  aliases,
  showNoResult = false,
  chatkey,
  showPromoCodeEligible,
  classes,
  hitsPerPage,
  disableScrollUp,
  onScrollUp,
  enableProductsOnHorizon,
  searchState,
  elasticSearchIndex,
  isInfiniteHits = false,
  hasMore = true,
  showNewTextForNoResultFound,
  hasSearchTerm,
  setActiveRefinements,
  enableDarkThemeTiles,
  enableMobileTileVariant2,
  enableMobileTileVariant3,
  isXsView,
  exclusiveDealBannerText,
  allowFilterDatesWithPermalink,
  showCurrentlyViewing,
  currentLocale,
  selectedPage
}) => {
  const [newHits, setNewHits] = useState([]);
  const [productsOnHorizon, setProductsOnHorizon] = useState([]);
  const [noResultData, setNoResultData] = useState([]);
  const [noInventoryFlag, setNoInventoryFlag] = useState(false);
  const [hasSearchResults, setHasSearchResults] = useState(false);
  const [queryId, setQueryId] = useState('');
  const {
    query,
    range,
    refinementList
  } = searchState || {};

  const cityForNoResult = refinementList?.city?.[0] || '';
  const searchWithQueryAndRange = !!query && (range?.availableDates?.min || range?.availableDates?.max);
  const startDate = range?.availableDates?.min && moment(range?.availableDates?.min, 'YYYYMMDD').format('MM/DD/YYYY');
  const endDate = range?.availableDates?.max && moment(range?.availableDates?.max, 'YYYYMMDD').format('MM/DD/YYYY');

  const fetchProducts = async() => {
    if (((hits.length < hitsPerPage && hasSearchResults) || (isInfiniteHits && !hasMore)) && enableProductsOnHorizon) {
      const result = await search({ searchState, index: elasticSearchIndex });
      const { hits: horizonHits = [] } = result || {};
      setProductsOnHorizon(horizonHits.slice(0, isInfiniteHits ? hitsPerPage : hitsPerPage - hits.length));
    } else if (enableProductsOnHorizon) {
      setProductsOnHorizon([]);
    }
  };

  function dateProximity(date) {
    const minDate = moment(range?.availableDates?.min, 'YYYYMMDD') || moment().format('YYYYMMDD');
    return Math.abs(minDate.diff(date, 'days'));
  }

  const fetchNoResultData = async() => {
    // Only run this if necessary - reduce API calls to Algolia ($$$)
    if (queryId && hits && !hits.length) {
      const { result, noInventory } = await searchFlexibleDates({ searchState, index: elasticSearchIndex });
      const { hits: noResultDataHits = [] } = result || {};
      setNoInventoryFlag(noInventory);
      const sortedData = (noResultDataHits || []).sort((first, second) => {
        const proximityA = Math.min(...first.availableDates.map(date => dateProximity(moment(date, 'YYYYMMDD'))));
        const proximityB = Math.min(...second.availableDates.map(date => dateProximity(moment(date, 'YYYYMMDD'))));
        return proximityA - proximityB;
      });
      setNoResultData(sortedData);
    }
  };

  useEffect(() => {
    if (!(hits || []).length) {
      setNewHits([]);
      if (isMobileView && setFixedHeight && fixedHeight) {
        setFixedHeight(false);
      }
    }
    if (!isEqual(newHits, hits) && enableProductsOnHorizon) {
      fetchProducts();
    }
    if (showNewTextForNoResultFound) {
      fetchNoResultData();
    }
    if ((hits && hits.length && !isEqual(newHits, hits))) {
      let tmpHits = hits;
      if (showToursWithAvailabilityOnly) {
        tmpHits = hits.filter(({ availableDates = [] }) => availableDates.length && availableDates.length > 0);
      }
      if (tmpHits && Array.isArray(adsTile) && (adsTile || []).length > 0) {
        (adsTile).forEach(({ adsTileNo, adsImage, adslink, ctaButtonContent }, idx) => {
          if (!isNaN(parseInt(adsTileNo))) {
            (tmpHits || []).splice(parseInt(adsTileNo), 0, {
              objectID: `hit-${idx}`,
              tiles: [adsImage],
              permalink: adslink,
              displayLabel: ['ad'],
              images: [adsImage],
              isAdTile: true,
              ctaButtonContent
            });
            if ((tmpHits || []).length > 12) {
              (tmpHits || []).pop();
            }
          }
        });
      }
      if (showEmbedSocialReview && !isEqual(newHits, tmpHits)) {
        loadEmbedSocialReview();
      }

      if (isMobileView && setFixedHeight) {
        if (tmpHits.length < 4 && fixedHeight) {
          setFixedHeight(false);
        }
        if (tmpHits.length >= 4 && !fixedHeight) {
          setFixedHeight(true);
        }
      }
      if (!disableScrollUp && tmpHits && Array.isArray(adsTile) && tmpHits.length < hitsPerPage) {
        onScrollUp();
      }
      setNewHits(tmpHits);
    }
  }, [hits, enableProductsOnHorizon]);

  useEffect(() => {
    fetchProducts();
  }, [enableProductsOnHorizon]);

  const updateActiveFacets = searchResults => {
    const { disjunctiveFacets } = searchResults || {};
    if (!disjunctiveFacets) {
      return;
    }
    const updatedFacets = (disjunctiveFacets || []).reduce((accum, facet) => {
      accum.add(facet.name);
      return accum;
    }, new Set());
    setActiveRefinements(updatedFacets);
  };

  const renderResults = hitsToRender =>
    (hitsToRender || []).map((hit, index) => {
      const {
        objectID,
        title,
        tileTitle,
        shortDescription,
        images,
        tiles,
        bookingTypeId,
        displayPrice,
        ctaButtonContent,
        defaultCurrency,
        permalink,
        propertyId,
        city,
        state,
        country,
        webCategoriesMain,
        exclusiveDeal,
        displayLabel,
        displayIcon,
        durationTiming,
        durationInMins,
        crossSellPermalinks,
        tbdPrice,
        numberOfRecentBookings,
        availabilityPct,
        discountedPrice,
        description,
        tileBannerLink,
        flipCard,
        propBookingType,
        discountedPriceWithFees,
        displayPriceWithFees,
        shouldShowFeeText,
        justFeePercentage,
        showStrikeThroughDiscountedPrice,
        __queryID,
        __position,
        embedReviewId,
        containsSpecialCoupon,
        containsDoubleRewards,
        webCategoriesSub,
        upToSavePercentage,
        tagDescription,
        tagColor,
        overrideDefaultTileTag,
        specialCouponBannerText,
        tagTextColor,
        isPriceInclusiveOfFees,
        localeTitles,
        localeTileTitles,
        isAdTile,
        adTileTitle,
        adTileSubtext,
        adTileMobileText,
        adTileButtonCtaText,
        adTileImage,
        adTileLink,
        brandTag
      } = hit;
      const isFeaturedTile = isVwoTestActive({
        campaignCookie: VWO_CAMPAIGNS.ALGOLIA_FEATURED_TILES,
        matchUrl: window.location.pathname,
        matchToUrls: INCLUDED_PORTS_FOR_FEATURED_TILES_CAMPAIGN
      }) && selectedPage === 1 && (index === 1 || index === 0);
      return (<Grid
        item
        key={`hit-${objectID}`}
        xs={enableMobileTileVariant3 ? 6 : 12}
        sm={6}
        md={6}
        lg={isAdTile ? 8 : 4}
        xl={isAdTile ? 6 : 3}
        className={classNames({
          [classes.firstMobileResult]: index === 0,
          ['isAdTile']: isAdTile,
          tileGridResult: true
        })}
      >
        <ResultItem
          localeTitles={localeTitles}
          localteTileTitles={localeTileTitles}
          currentLocale={currentLocale}
          tealiumTrack={tealiumTrack}
          showCurrentlyViewing={showCurrentlyViewing}
          chatkey={chatkey}
          showPromoCodeEligible={showPromoCodeEligible}
          showPriceWithFees={showPriceWithFees}
          images={images}
          tiles={tiles}
          title={title}
          tileTitle={tileTitle}
          objectID={objectID}
          shortDescription={shortDescription}
          bookingTypeId={bookingTypeId}
          propertyId={propertyId}
          displayPrice={displayPrice}
          defaultCurrency={defaultCurrency}
          permalink={permalink}
          city={(((aliases || {})[propertyId] || {})[bookingTypeId] || {}).city || city}
          state={state}
          country={country}
          webCategoriesMain={webCategoriesMain}
          exclusiveDeal={exclusiveDeal}
          displayLabel={
            shouldHideAdlabels && shouldAddCtaButton && displayLabel ?
              displayLabel : (allowedDisplayLabels || displayLabel)
          }
          displayIcon={displayIcon}
          durationTiming={durationTiming}
          durationInMins={durationInMins}
          crossSellPermalinks={crossSellPermalinks}
          siteId={siteId}
          abTestVariant={abTestVariant}
          tbdPrice={tbdPrice}
          numberOfRecentBookings={numberOfRecentBookings}
          availabilityPct={availabilityPct}
          recentBookingsThreshold={recentBookingsThreshold}
          sellOutThreshold={sellOutThreshold}
          allowFilterDatesWithPermalink={allowFilterDatesWithPermalink}
          startDate={startDate}
          endDate={endDate}
          tourCurrencies={tourCurrencies}
          propBookingType={propBookingType}
          discountedPrice={discountedPrice}
          description={description}
          flipCard={flipCard}
          tileBannerLink={tileBannerLink}
          discountedPriceWithFees={discountedPriceWithFees}
          displayPriceWithFees={displayPriceWithFees}
          showStrikeThroughDiscountedPrice={showStrikeThroughDiscountedPrice}
          isMobileView={isMobileView}
          ctaButtonContent={ctaButtonContent}
          shouldRedirectAd={shouldRedirectAd}
          shouldAddCtaButton={shouldAddCtaButton}
          __queryID={__queryID}
          __position={__position}
          hit={hit}
          searchIndex={searchIndex}
          sendAlgoliaEvent={sendAlgoliaEvent}
          setAlgoliaSearchInfo={setAlgoliaSearchInfo}
          searchExperienceV3IsEnabled={searchExperienceV3IsEnabled}
          embedReviewId={showEmbedSocialReview && embedReviewId}
          containsSpecialCoupon={containsSpecialCoupon}
          containsDoubleRewards={containsDoubleRewards}
          shouldHideAdlabels={shouldHideAdlabels}
          webCategoriesSub={webCategoriesSub}
          upToSavePercentage={upToSavePercentage}
          tagDescription={tagDescription}
          tagColor={tagColor}
          overrideDefaultTileTag={overrideDefaultTileTag}
          specialCouponBannerText={specialCouponBannerText}
          tagTextColor={tagTextColor}
          enableDarkThemeTiles={enableDarkThemeTiles}
          enableMobileTileVariant2={enableMobileTileVariant2}
          isXsView={isXsView}
          enableMobileTileVariant3={enableMobileTileVariant3}
          showNewTextForNoResultFound={showNewTextForNoResultFound}
          cityForNoResult={cityForNoResult}
          hasSearchTerm={hasSearchTerm}
          showNoResult={showNoResult}
          exclusiveDealBannerText={exclusiveDealBannerText}
          noInventoryFlag={noInventoryFlag}
          hasSearchResults={hasSearchResults}
          searchWithQueryAndRange={searchWithQueryAndRange}
          isPriceInclusiveOfFees={isPriceInclusiveOfFees}
          justFeePercentage={justFeePercentage}
          shouldShowFeeText={shouldShowFeeText}
          isAdTile={isAdTile}
          adTileTitle={adTileTitle}
          adTileSubtext={adTileSubtext}
          adTileMobileText={adTileMobileText}
          adTileButtonCtaText={adTileButtonCtaText}
          adTileImage={adTileImage}
          adTileLink={adTileLink}
          isFeaturedTile={isFeaturedTile}
          toShowPriceCents={
            Array.isArray(toShowCentsPropertyIds) && (toShowCentsPropertyIds || []).includes(propertyId || '')
          }
          brandTag={brandTag}
          isDisplayPriceWithFeesCampaignActive={isDisplayPriceWithFeesCampaignActive}
        />
      </Grid>
      );
    });

  const SkeletonLoader = () => (
    <Card
      className={classNames(classes.card, 'cardV2Inline', { [classes.cardMobile]: isMobileView })}
    >
      <CardMedia className={classes.media}/>
      <CardContent>
        <div className={classNames('tileTitleStyling', classes.eventNameSkeleton)}></div>
        <div className={classes.cityNameSkeleton}></div>
      </CardContent>
    </Card>
  );
  const skeletonLoaders = Array.from(Array(hitsPerPage).keys()).map(number => (
    <Grid
      item
      key={`skeleton-loader-${number}`}
      xs={3}
      sm={3}
      md={3}
      lg={4}
      xl={3}
      className={classNames({
        [classes.firstMobileResult]: number === 0,
        tileGridResult: true
      })}
    >
      <SkeletonLoader key={number}/>
    </Grid>
  ));

  const renderNoResults = loading => (!loading ? <Grid
    item
    xs={12}
  >
    <Typography gutterBottom variant="h5" color="primary">
      <Text message={showNewTextForNoResultFound ? 'noResultsFoundWithFlag.title' : 'noResultsFound.title'}/>
    </Typography>
    <Typography gutterBottom variant="body2" color="textSecondary" className={classes.felxibleDateMessage}>
      <Text message={showNewTextForNoResultFound ? 'noResultsFoundWithFlag.message' : 'noResultsFound.message'}/>
    </Typography>
    {showNewTextForNoResultFound &&
    (
      noResultData.length > 0 ? <div className={classes.noResultsProductsOnHorizonContainer}>
        { showNewTextForNoResultFound && <>
          <Typography gutterBottom variant="h6" color="black">
            <Text message={(!cityForNoResult || (noInventoryFlag && cityForNoResult)) ? 'trendingNearYou.title' :
              (hasSearchTerm && !noInventoryFlag && !searchWithQueryAndRange ?
                'flexibleDateWithFlagForSearchTerm.title' :
                noInventoryFlag ?
                  'flexibleDateWithFlagForDateAndTerm.title' : 'flexibleDateWithFlag.title') }
            values={[
              ((hasSearchTerm && !searchWithQueryAndRange) || noInventoryFlag) && (cityForNoResult && !noInventoryFlag)
                ? cityForNoResult : '']}
            />
          </Typography>
          <Typography gutterBottom variant="body2" color="textSecondary" className={classes.felxibleDateMessage}>
            <Text message={(!cityForNoResult || (noInventoryFlag && cityForNoResult))
              ? 'trendingNearYou.message' : (noInventoryFlag ?
                'flexibleDateWithFlagForDateAndTerm.message' :
                (hasSearchTerm && !searchWithQueryAndRange) ?
                  'flexibleDateWithFlagForSearchTerm.message' : 'flexibleDateWithFlag.message')}/>
          </Typography></>}
        {!!(noResultData || []).length && showNewTextForNoResultFound &&
      <Grid container spacing={enableMobileTileVariant3 && isXsView ? 1 : 4} className={'searchResultsGrid'}>
        {renderResults(noResultData)}
      </Grid>
        }
      </div>
        :
        (
          <Grid container spacing={2}>
            {skeletonLoaders}
          </Grid>
        )
    )
    }

  </Grid> : (
    Array.from(Array(hitsPerPage).keys()).map(number =>
      <Grid
        item
        key={`skeleton-loader-${number}`}
        xs={12}
        sm={6}
        md={6}
        lg={4}
        xl={3}
        className={classNames({
          [classes.firstMobileResult]: number === 0,
          tileGridResult: true
        })}
      >
        <SkeletonLoader key={number}/>
      </Grid>
    )
  )
  );

  const ConnectedResults = connectStateResults(
    ({ searchResults, children }) => {
      const { queryID: searchResultsQueryId } = searchResults || {};
      if (searchResultsQueryId !== queryId) {
        setQueryId(searchResultsQueryId);
        updateActiveFacets(searchResults);
      }
      if (searchResults && !searchResults.nbHits && hasSearchResults) {
        setHasSearchResults(false);
      } else if (searchResults && searchResults.nbHits && !showNoResult && !hasSearchResults) {
        setHasSearchResults(true);
      }
      // eslint-disable-next-line no-nested-ternary
      return (searchResults && searchResults.nbHits !== 0 && !showNoResult ? (
        children
      ) : !(productsOnHorizon || []).length ? (
        <Grid container spacing={4} className={'searchResultsGrid'}>
          {renderNoResults((!searchResults && !showNoResult))}
        </Grid>
      ) : (
        <>
          <div className={classes.noResultsProductsOnHorizonContainer}>
            <div className={classes.noResultsProductsOnHorizonTitles}>
              <Typography gutterBottom variant="h4" className={classes.horizonHeader}>
                <Text message={'productsOnHorizon.header'}/>
              </Typography>
              <Typography gutterBottom variant="body2" color="textSecondary">
                <Text message={'productsOnHorizon.subheader'}/>
              </Typography>
            </div>
            <Grid container spacing={enableMobileTileVariant3 && isXsView ? 2 : 4} className={'searchResultsGrid'}>
              {renderResults(productsOnHorizon)}
            </Grid>
          </div>
        </>
      ));
    }
  );
  return (
    <ConnectedResults>
      <Grid container spacing={enableMobileTileVariant3 && isXsView ? 2 : 4} className={'searchResultsGrid'}>
        {renderResults(newHits)}
      </Grid>
      {!!(productsOnHorizon || []).length &&
        <div className={classes.onTheHorizonContainer}>
          <div className={classes.productsOnHorizonTitle}>
            <Typography gutterBottom variant="h4" className={classes.horizonHeader}>
              <Text message={'productsOnHorizon.header'}/>
            </Typography>
            <Typography gutterBottom variant="body2" color="textSecondary">
              <Text message={'productsOnHorizon.subheader'}/>
            </Typography>
          </div>
          <Grid container spacing={enableMobileTileVariant3 && isXsView ? 2 : 4} className={'searchResultsGrid'}>
            {renderResults(productsOnHorizon)}
          </Grid>
        </div>
      }
    </ConnectedResults>
  );
};

Results.contextTypes = {
  localize: PropTypes.func.isRequired
};

export default withStyles(styles)(Results);
